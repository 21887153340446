import { defineStore } from "pinia"
import { useGraphQLQuery } from '@/composables/useApolloClient'

export type FAQState = {
  pages: FAQPage[];
  banner: string;
  bannerIcon: string;
  info: string;
  isFetching: boolean;
}

export type FAQPage = {
  title: string;
  route: string;
  content: string;
  description: string;
  icon: string;
}

export type FAQPageResponse = {
  title: string;
  route: string;
  content: string;
  description: string;
  icon: {
    data: {
      attributes: {
        name: string
      }
    }
  }
}

export type FAQResponse = {
  faq: {
    data: {
      attributes: {
        Pages: FAQPageResponse[]
        banner_image: {
          data: {
            attributes: {
              url: string;
            }
          }
        }
        title_icon_image: {
          data: {
            attributes: {
              url: string;
            }
          }
        }
        organization_info: string
      }
    }
  }
}

export const useFAQStore = defineStore('faq', {
  state: (): FAQState => ({
    pages: [],
    banner: '',
    bannerIcon: '',
    info: '',
    isFetching: false
  }),
  actions: {
    async fetchFAQ() {
      this.isFetching = true

      const { fetch } = useGraphQLQuery<FAQResponse>(gql`
        query {
          faq {
            data{
              attributes {
                Pages {
                  title
                  route
                  content
                  description
                  icon {
                    data {
                      attributes {
                        name
                      }
                    }
                  }
                }
                banner_image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                title_icon_image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                organization_info 
              }
            }
          }
        }
      `)
      const data = await fetch()


      if (data && data.faq && data.faq.data) {
        const faq = data.faq.data?.attributes
        this.banner = faq.banner_image.data?.attributes.url
        this.bannerIcon = faq.title_icon_image.data?.attributes.url
        this.info = faq.organization_info

        const pages: FAQPage[] = faq.Pages.map((page: FAQPageResponse) => {
          return {
            title: page.title,
            route: page.route,
            content: page.content,
            description: page.description,
            icon: page.icon.data?.attributes.name
          }
        })
        this.pages = pages
        this.isFetching = false
      }
    },
    async fetchFAQRoutes() {
      this.isFetching = true

      const { fetch } = useGraphQLQuery<FAQResponse>(gql`
        query {
          faq {
            data{
              attributes {
                Pages {
                  title
                  route
                }
              }
            }
          }
        }
      `)
      const data = await fetch()

      if (data && data.faq) {
        const faq = data.faq.data.attributes

        const pages: FAQPage[] = faq.Pages.map((page: FAQPageResponse) => {
          return {
            title: page.title,
            route: page.route,
          }
        })
        this.pages = pages
        this.isFetching = false
      }
    }
  }
})
